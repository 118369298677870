

























































































































































































import Vue from "vue";

export default Vue.extend({
  name: "TermsOfService",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  data: () => ({}),
  methods: {
    goToHome() {},
    goToAboutUs() {},
    goToContact() {},
    goToSignUp() {
      this.$router.push({ name: "landing" });
    },
    goToLogIn() {
      this.$router.push({ name: "Login" });
    },
  },
  computed: {
    privacyPolicyUrl(): string {
      let routeData = this.$router.resolve({ name: "PrivacyPolicy" });
      return routeData.href;
    },
  }
});
